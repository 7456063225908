import React, { useEffect, useState } from 'react'
import { Box } from "@mui/material";
import "./Gallery.scss"
import { InstagramEmbed } from 'react-social-media-embed';


function Gallery() {

  const [images, setImages] = useState([]);

  let screenWidth = window.screen.width;

  const access_token = process.env.REACT_APP_INSTA_TOKEN

  const fields_reqested = 'id,media_type,media_url,caption,permalink'


  useEffect(() => {
    fetch(`https://graph.instagram.com/me/media?fields=${fields_reqested},username&access_token=${access_token}`, 
      {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "User-Agent": "Mozilla/5.0 (Linux; Android 6.0; Nexus 5 Build/MRA58N) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/117.0.0.0 Mobile Safari/537.36",
      }
      })
    .then(response => {
      if (response.ok) {
        response.json()
        .then((data) => {
          setImages(data.data)
        })
      }
      else{
        console.log(response);
      }
    })
    //deal with dependency arrays
  }, [])

  const filteredImages = images.filter((image) => {
    return image.media_type === 'IMAGE' || image.media_type === 'CAROUSEL_ALBUM'
  })

  let imagesToDisplay = 6;

  if (screenWidth <= 425) {
    imagesToDisplay = 5
  }
  else if (screenWidth > 425  && screenWidth <= 1024)
  {
    imagesToDisplay = 6
  }
  else {
    imagesToDisplay = 8
  }

  const slicedImage = filteredImages.slice(0, imagesToDisplay);

  return (
    <div style={{marginTop:'1rem'}}>
    <Box className = 'image-Box'>
      {slicedImage?.map((image, index) => (
        // <div className='instaBox'>
        //   <img class = "image-Gallery" key = {index} alt = {image.id} src = {image.media_url}></img>
        //   <h3>{image.caption}</h3>
        // </div>
        <div key = {index} style={{ display: 'flex', justifyContent: 'center' }}>
          <InstagramEmbed linkText = {image.caption} captioned = {true} url={image.permalink} width={328} />
        </div>
      ))
      }
    </Box>
    </div>
  )
}

export default Gallery
